import { useAuth } from './useAuth'
import { useStores } from './useStores'

export const useValidateAuth = () => {
  const { isLogin } = useAuth()
  const { store } = useStores()

  const login = (url?: string) => {
    store.modal?.setLoginVisible(true)
  }

  const validateIsLogin = (url?: string) => {
    if (!isLogin) {
      login(url)
      return false
    }
    return true
  }

  /** 异步调用登录的场景：
   * ios文件上传控件调用相册时，跟登录弹窗冲突了，导致无法调取登录弹窗；
   * 解决方案：ios相册关闭动画延时约300ms，通过延时500ms调用login来解决；
   */
  const validateIsLoginAsync = (url?: string) => {
    if (!isLogin) {
      setTimeout(() => {
        login(url)
      }, 500)
      return false
    }
    return true
  }

  return {
    /** 同步登录调用 */
    validateIsLogin,
    /** 异步登录调用 */
    validateIsLoginAsync,
  }
}
