import { useStores } from './useStores'

export const useAuth = () => {
  const { store } = useStores()

  // 是否已登录
  const isLogin = !!store.token

  return {
    isLogin,
  }
}
