export default function Logout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.50098 3.16701H3.16798V12.833H6.50098C6.67788 12.833 6.84754 12.9033 6.97262 13.0284C7.09771 13.1535 7.16798 13.3231 7.16798 13.5C7.16798 13.6769 7.09771 13.8466 6.97262 13.9716C6.84754 14.0967 6.67788 14.167 6.50098 14.167H3.00098C2.69148 14.167 2.39465 14.0441 2.17579 13.8252C1.95694 13.6063 1.83398 13.3095 1.83398 13V3.00001C1.83398 2.6905 1.95694 2.39367 2.17579 2.17481C2.39465 1.95596 2.69148 1.83301 3.00098 1.83301H6.50098C6.67788 1.83301 6.84754 1.90328 6.97262 2.02837C7.09771 2.15345 7.16798 2.32311 7.16798 2.50001C7.16798 2.67691 7.09771 2.84656 6.97262 2.97165C6.84754 3.09673 6.67788 3.16701 6.50098 3.16701Z"
        fill="#5C4017"
      />
      <path
        d="M10.406 4.90301C10.666 4.64301 11.088 4.64301 11.348 4.90301L13.973 7.52901C14.233 7.78901 14.233 8.21101 13.973 8.47101L11.348 11.096C11.2214 11.2128 11.0546 11.2761 10.8824 11.2727C10.7103 11.2693 10.5461 11.1994 10.4243 11.0777C10.3024 10.956 10.2324 10.7919 10.2288 10.6197C10.2252 10.4476 10.2883 10.2807 10.405 10.154L11.892 8.66701H6.50198C6.32509 8.66701 6.15543 8.59674 6.03034 8.47165C5.90526 8.34656 5.83498 8.17691 5.83498 8.00001C5.83498 7.82311 5.90526 7.65345 6.03034 7.52837C6.15543 7.40328 6.32509 7.33301 6.50198 7.33301H11.892L10.405 5.84601C10.28 5.72093 10.2098 5.55134 10.2098 5.37451C10.2098 5.19768 10.28 5.02808 10.405 4.90301H10.406Z"
        fill="#5C4017"
      />
    </svg>
  )
}
