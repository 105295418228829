import { CDNImage } from '~/components/ui/CDNImage'
import { MyImage } from '~/components/ui/MyImage'
import { ArtworkItemProps } from '~/configs'

export const RecommendArtwork: React.FC<{
  onItemClick: (it: ArtworkItemProps) => void
  data: ArtworkItemProps[]
}> = ({ onItemClick, data }) => {
  return (
    <div className="flex flex-wrap justify-start -m-2 px-4">
      {data.map(it => (
        <div
          className="relative w-[50%] h-[192px] p-2 rounded-lg overflow-hidden"
          key={it.id}
          onClick={() => onItemClick(it)}
        >
          <MyImage
            tag="v400"
            className="w-[100%] h-[100%] rounded-lg"
            src={it.url}
          />
          <div className="absolute bottom-2 left-2 right-2 text-center py-[2px] px-1 rounded-b-lg bg-[rgba(0,0,0,.32)] text-[#fdecb6]">
            <div className="flex items-center justify-center">
              <CDNImage className="w-4" src="/images/homepage/zan@2x.png" />
              <span className="ml-1">一键同款</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
