import { isIOS } from '~/utils'

const HOST = 'https://m.wujieai.com'

const config = {
  scheme: {
    protocol: 'supernodetrade',
    host: '',
  },
  intent: {
    package: 'com.blockmeta.trade',
    category: 'android.intent.category.BROWSABLE',
    action: 'android.intent.action.VIEW',
    scheme: 'supernodetrade',
  },

  fallback: `${HOST}/download`,
  // 微信浏览器可能不能调起appStore链接，考虑到微信浏览器，全部跳转到下载页面
  appstore: `${HOST}/download`,
  // 3000ms时火狐有时候调起的太慢了，会跳下载页面
  timeout: isIOS() ? 0 : 3000,
}

export const useCallApp = () => {
  return {
    callLib: () => {
      const CallApp = require('callapp-lib')
      return new CallApp(config)
    },
  }
}
