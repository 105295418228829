/** 用老的版本是因为新版本展位图有问题 */
import Image, { ImageLoaderProps } from 'next/legacy/image'
import React, { useMemo } from 'react'
import classNames from 'classnames'

/**
 * tag说明
 * v400 表示就改图片质量为75且宽度为400
 * v800 表示就改图片质量为75且宽度为800
 * v1200  表示就改图片质量为75且宽度为1200
 * **/

export const MyImage: React.FC<{
  src: string | null | undefined
  className?: string
  style?: React.CSSProperties
  quality?: number
  alt?: string
  lazy?: boolean
  tag?: 'v400' | 'v600' | 'v800' | 'v1200'
  onClick?: () => void
  dark?: boolean
  sizes?: string
  rotateDeg?: boolean
  unoptimized?: boolean
}> = ({
  src,
  className,
  style,
  quality = 75,
  alt = '',
  lazy = true,
  onClick,
  tag,
  dark = true,
  sizes,
  rotateDeg,
  unoptimized = false,
}) => {
  // 七牛的图片处理API
  // https://developer.qiniu.com/dora/1279/basic-processing-images-imageview2
  const myLoader = ({ src, quality }: ImageLoaderProps) => {
    if (rotateDeg) {
      // imageMogr2/rotate/${rotateDeg}/thumbnail/800x
      return tag
        ? `${src}?imageMogr2/rotate/90/thumbnail/${tag.replace('v', '')}x`
        : src
    }
    return tag
      ? `${src}?imageView2/2/w/${tag.replace('v', '')}/q/${quality}`
      : `${src}?imageView2/2/q/${quality}`
  }

  const defaultSrc = useMemo(() => {
    return !!dark
      ? '/images/common/placeholder-dark.png'
      : '/images/common/placeholder.png'
  }, [dark])

  return (
    <div
      className={classNames(className, 'relative overflow-hidden', {
        'cursor-pointer': !!onClick,
      })}
      style={style}
      onClick={onClick}
    >
      <Image
        src={src || defaultSrc}
        placeholder="blur"
        blurDataURL={defaultSrc}
        // lazyBoundary="0px"
        loading={lazy ? 'lazy' : 'eager'}
        layout="fill"
        quality={quality}
        loader={myLoader}
        className="object-cover"
        alt={alt}
        sizes={sizes}
        unoptimized={unoptimized}
      />
    </div>
  )
}
