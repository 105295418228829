import { useMutation } from '@apollo/client'
import { Dialog, Swiper } from 'antd-mobile'
import Cookies from 'js-cookie'
import { observer } from 'mobx-react'
import { GetServerSideProps, NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { SEOHead } from '~/components/business/SEOHead'
import { GlobalRequest } from '~/components/global-requset'
import Logout from '~/components/icons/Logout'
import { RecommendArtwork } from '~/components/pages/homepage/RecommendArtwork'
import { CDNImage } from '~/components/ui/CDNImage'
import { FeatureBtn } from '~/components/ui/FeatureBtn'
import { artworkData, ArtworkItemProps } from '~/configs'
import { LOGOUT } from '~/graphqls/gqls'
import { logout } from '~/graphqls/types'
import { useAuth } from '~/hooks/useAuth'
import { useCallApp } from '~/hooks/useCallApp'
import { useValidateAuth } from '~/hooks/useValidateAuth'
import { Layout } from '~/layouts/default'
import { toCDNImage, trackEvent } from '~/utils'

const Home: NextPage = () => {
  const [logoutAction] = useMutation<logout>(LOGOUT)
  const { validateIsLogin } = useValidateAuth()
  const { isLogin } = useAuth()
  const router = useRouter()
  const { callLib } = useCallApp()

  const swiperRef = useRef<any>(null)

  const onCreate = () => {
    const nextUrl = '/artwork/create'

    if (validateIsLogin()) {
      router.push(nextUrl)
    }
  }

  const onLogout = () => {
    logoutAction()
    Cookies.remove('gate-token')
    router.replace('/')
  }

  const onToApp = (it: ArtworkItemProps) => {
    trackEvent({
      category: '活动首页',
      label: '一键同款',
    })

    Dialog.show({
      bodyClassName: 'feature-dialog',
      title: '提醒',
      content: (
        <div className="p-4 text-center">
          您可前往《无界AI》进行免费的一键同款，同时解锁更多功能
        </div>
      ),
      closeOnAction: true,
      actions: [
        [
          {
            key: 'confirm',
            text: '前往无界AI',
            className: 'dialog-btn--primary',
            onClick: async () => {
              trackEvent({
                category: '弹窗',
                label: '前往无界AI',
              })
              callLib().open({
                path: `web?url=https://m.wujieai.com/plaza/${it.id}`,
              })
            },
          },
          {
            key: 'close',
            text: '取消',
            className: 'dialog-btn--default',
          },
        ],
      ],
    })
  }

  const handleMore = () => {
    swiperRef.current.swipeNext()
  }

  useEffect(() => {
    trackEvent({
      category: '活动首页',
      label: '页面被访问',
    })
  }, [])

  return (
    <Layout>
      <SEOHead />
      <Swiper
        direction="vertical"
        indicator={() => null}
        ref={swiperRef}
        style={{ '--height': '100vh' }}
      >
        <Swiper.Item
          style={{
            background: `url(${toCDNImage(
              '/images/homepage/bg@2x.jpg'
            )}) top center`,
            backgroundSize: 'cover',
          }}
        >
          <header className="flex items-center justify-between pl-6 pr-2 h-10 bg-[rgba(255,255,255,.64)]">
            <CDNImage className="h-6 w-auto" src="/images/common/logo@2x.png" />
            {isLogin && (
              <div
                onClick={onLogout}
                className="w-[84px] h-7 flex items-center justify-center  bg-white bg-opacity-[0.64] rounded-2xl text-[#5C4017] text-[12px] font-semibold"
              >
                <div className="mr-1">退出登录</div>
                <Logout />
              </div>
            )}
          </header>
          <div className="absolute top-[56px] left-0 right-0 text-right">
            <span
              className="font-song text-[16px] leading-5 px-2 pt-[3px] pb-[5px] rounded-l-full text-[#FDECB6] border-[#fdecb6] border-[1px] -mr-[1px] "
              style={{
                backgroundColor: 'rgba(92, 64, 23, 0.56)',
              }}
              onClick={() => router.push('/us/help')}
            >
              活动须知
            </span>
          </div>
          <CDNImage
            className="w-[280px] mt-8 mx-auto"
            src="/images/homepage/banner-title@2x.png"
          />
          <CDNImage
            className="w-[300px] h-auto mx-auto"
            src="/images/homepage/case-v2@2x.png"
          />
          <FeatureBtn
            className="-mt-[10px] mb-4"
            type="primary"
            size="md"
            onClick={onCreate}
          >
            解锁神隐同款写真
          </FeatureBtn>

          <div className="flex justify-center" onClick={handleMore}>
            <span className="flex items-center justify-center rounded-full py-1 px-2  text-[16px] leading-none bg-[rgba(92,64,23,.32)] text-neutral-900">
              <span className="font-song -mt-[2px]">下滑查看更多创作</span>
              <CDNImage
                className="ml-2 w-[16px]"
                src="/images/common/arrow-down@2x.png"
              />
            </span>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <CDNImage
            className="p-4"
            src="/images/homepage/recommend-title@2x.png"
          ></CDNImage>
          <RecommendArtwork
            data={artworkData.slice(0, 6)}
            onItemClick={it => onToApp(it)}
          />
        </Swiper.Item>
        <Swiper.Item>
          <CDNImage
            className="p-4"
            src="/images/homepage/recommend-title@2x.png"
          ></CDNImage>
          <RecommendArtwork
            data={artworkData.slice(6, 12)}
            onItemClick={it => onToApp(it)}
          />
        </Swiper.Item>
      </Swiper>
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async context => {
  const { initialState, client } = await GlobalRequest(context)

  return {
    props: {
      initialState,
    },
  }
}

export default observer(Home)
